import React from "react";
import "./Arti.css";
import AartiImage from "./assets/images/Aarti.jpg";
import MainImage from "./assets/images/Main.jpg";
import mobPage from "./assets/images/mob-page.jpg";
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";

const Flame = () => (
  <>
    <Header />
    <div className="desktop flame-container">
      <img src={AartiImage} alt="Aarti" className="full-width-image" />
      <img src={MainImage} alt="Main" className="full-width-image" />
    </div>

    <div className="mobile w-full">
      <img src={mobPage} alt="" />
    </div>
    <Footer />
  </>
);

export default Flame;
