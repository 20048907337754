import React from 'react';
import "./styles.css"; 
import counselling1 from "./assets/counselling1.jpg";
import counselling2 from "./assets/counselling2.jpg";
import mobPage from "./assets/page-mobile.jpg";
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';

function Counselling() {
  return (
    <div>
        <Header />
    <div className='flex-col desktop w-full bg-[#ffecdb]' >
      <img src={counselling1}  />
      <img src={counselling2}  />
    </div>

    <div className='mobile w-full'>
      <img src={mobPage}  />
    </div>
    <Footer />
    
    </div>
  )
}

export default Counselling
