import React from 'react'
import Slider1 from './Slider1';
import sunday from './assets/sunday.jpg'
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
function SundayFeast() {
  return (
    <div>
      <Header/>
      <div className="relative desktop w-full">
        <img src={sunday} alt="Saturday Satsang" className="w-full h-auto" />
        {/* Slider1 component positioned absolutely on top of the image */}
        <div className="absolute left-0 w-full top-0 mt-[70%]" >
          <Slider1 />
        </div>
      </div>

      <div className='mobile w-full'>
        <Slider1 />
      </div>
      <Footer />
    </div>
);
  
}

export default SundayFeast
